// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* WARNING: This section must be updated if _variables.ts is changed */
/* PERCENTAGE COLOURS */
.text-medium {
  font-size: 14px;
}

.coloured-dot {
  height: 36px;
  width: 36px;
}

.colour-panel {
  border: 5px;
  width: 140px;
}

.project-name-col {
  flex: 0 0 21%;
  max-width: 21%;
}

.variations-chevron-col {
  flex: 0 0 4%;
  max-width: 4%;
}

.variations-list {
  background-color: #efefef;
}

.variations-list a {
  text-decoration: none;
  color: #000000;
}

.generic-table-header .coloured-dot,
.status-coloured-dot {
  height: 10px;
  width: 10px;
  margin-top: 2px;
  min-width: 10px;
}

.image-container {
  display: inline-block;
  height: 45px;
}
.image-container .image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.coating-system-images-container {
  width: 84px;
}

.results-text {
  font-weight: 600;
}

.sortable-fallback {
  display: none;
}

::ng-deep .dropdown-style .ng-select-container .ng-value-container .ng-value {
  padding-left: 10px;
}

.clients-col {
  flex: 0 0 11%;
  max-width: 11%;
}

.comments-col {
  flex: 0 0 4%;
  max-width: 4%;
}

::ng-deep .ng-dropdown-panel {
  min-width: 10.5rem;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
